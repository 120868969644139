import React from 'react'

const AgendaTermin = ({ event, onClick }) => {
  return (
    <button
      onClick={() => {
        onClick(event)
      }}
      className="button is-text"
    >
      {event.title}
    </button>
  )
}

export default AgendaTermin
