import React, { Component } from 'react'
import Input from '../../../wal-common/src/components/Form/Input/Input'
import Captcha from '../../../wal-common/src/components/Form/Captcha/Captcha'
import Form from '../../../wal-common/src/components/Form/Form'
import BEMHelper from 'react-bem-helper'

import './SendAnfrageForm.scss'

const classes = new BEMHelper({
  name: 'SendAnfrageForm',
})

class SendAnfrageForm extends Component {
  render() {
    const { termin, onSuccess } = this.props

    return (
      <Form
        {...classes()}
        name="termin-anfrage"
        hidden={{
          termin,
        }}
        onSuccess={onSuccess}
      >
        <div className="columns">
          <div className="column">
            <Input
              field="name"
              label="Name"
              placeholder="Max Musterman"
              required
            />
          </div>
          <div className="column">
            <Input
              field="contact"
              label="Kontakt"
              placeholder="Telefon oder E-Mail"
              required
            />
          </div>
        </div>

        <div className="columns">
          <div {...classes('CaptchaFix', null, 'column')} >
            <Captcha/>
          </div>
          <div {...classes('Button', null, 'column has-text-right')}>
            <button className="button is-success"
                    type="submit">
              Anfrage senden
            </button>
          </div>
        </div>
      </Form>
    )
  }
}

export default SendAnfrageForm
