import React from 'react'
import classNames from 'classnames'
import { Field } from 'react-form'
import Message from '../Message/Message'

const Input = ({required, field, ...props}) => {
  const validate = value => {
    if (required) {
      return {
        error: !value || !value.trim() ? 'Darf nicht leer sein' : null,
        success: value && value.trim() ? true : null
      }
    }

    return {}
  }

  return (
    <Field validate={validate} field={field}>
      {fieldApi => {
        // Remember to pull off everything you dont want ending up on the <input>
        // thats why we pull off onChange, onBlur, and field
        // Note, the ...rest is important because it allows you to pass any
        // additional fields to the internal <input>.
        const {
          onChange,
          onBlur,
          label,
          iconLeft,
          iconRight,
          ...rest
        } = props

        const {
          value,
          error,
          warning,
          success,
          setValue,
          setTouched,
        } = fieldApi

        let color;
        let message = null;

        if (error) {
          color = 'is-danger'
          message = error
        } else if (warning) {
          color = 'is-warning'
          message = warning
        } else if (success) {
          color = 'is-success'
          message = success
        }

        return (
          <div className="field">
            {label && <label className="label">{label}</label>}
            <div
              className={classNames('control', {
                'has-icons-left': iconLeft,
                'has-icons-right': iconRight,
              })}
            >
              <input
                {...rest}
                type="text"
                className={classNames('input', color)}
                value={value || ''}
                name={field}
                onChange={e => {
                  setValue(e.target.value)
                  if (onChange) {
                    onChange(e.target.value, e)
                  }
                }}
                onBlur={e => {
                  setTouched()
                  if (onBlur) {
                    onBlur(e)
                  }
                }}
              />
              {iconLeft && (
                <span className="icon is-small is-left">{iconLeft}</span>
              )}
              {iconRight && (
                <span className="icon is-small is-right">{iconRight}</span>
              )}
            </div>
            {message && <Message color={color} message={message} />}
          </div>
        )
      }}
    </Field>
  )
}

export default Input
