import React, { Component } from 'react'
import { Field } from 'react-form'
import ReCAPTCHA from 'react-google-recaptcha'
import Message from '../Message/Message'

const Captcha = () => {
  const validate = value => {
    return {
      error: !value && 'Are you robot? :)',
    }
  }

  return (
    <Field validate={validate} field="captcha">
      {fieldApi => {
        const { error, setValue, setTouched } = fieldApi

        return (
          <React.Fragment>
            <ReCAPTCHA
              sitekey="6LedQ3IUAAAAAIGm3zil7UzYvuR0QplPK1KUwDlZ"
              onChange={result => {
                setValue(!!result)
                setTouched(true)
              }}
            />
            {error && <Message color="is-danger" message={error} />}
          </React.Fragment>
        )
      }}
    </Field>
  )
}

export default Captcha
