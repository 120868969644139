import React, { Component } from 'react'
import BigCalendar from 'react-big-calendar'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import 'moment/locale/de'
import BEMHelper from 'react-bem-helper'
import Modal from '../../wal-common/src/components/Modal/Modal'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './Calendar.scss'
import config from '../../config'
import AgendaTermin from './AgendaTermin/AgendaTermin'
import SendAnfrageForm from './SendAnfrageForm/SendAnfrageForm'

const moment = extendMoment(Moment)

const classes = new BEMHelper({
  name: 'Calendar',
})

moment.locale('de')
const localizer = BigCalendar.momentLocalizer(moment)

const FREE_TIMESLOTS = {
  // WEEKDAY: starttimes of slots
  0: ['09:00', '11:00'],
  1: ['19:00'],
  2: ['19:00'],
  3: ['09:00', '11:00'],
  4: ['09:00'],
}

const TERMIN_DURATION = {
  minutes: 120,
}

class Calendar extends Component {
  state = {
    termine: [],
    selectedTermin: null,
    formSuccessfullySubmitted: false,
  }

  onSelectEvent = selectedTermin => {
    this.setState({
      selectedTermin,
    })
  }

  closeModal = () => {
    this.setState({
      formSuccessfullySubmitted: false,
      selectedTermin: null,
    })
  }

  setFreeSlots() {
    const it = moment().add(1, 'days')
    const endOfPlanning = it
      .clone()
      .add(3, 'month')
      .endOf('month')
    let weekday
    const termine = []

    const url = new URL(
      `https://www.googleapis.com/calendar/v3/calendars/${
        config.googleCalendarId
      }/events`
    )
    const params = {
      timeMax: endOfPlanning.toISOString(),
      timeMin: it.toISOString(),
      key: config.googleApiKey,
    }
    Object.keys(params).forEach(key =>
      url.searchParams.append(key, params[key])
    )

    fetch(url)
      .then(response => response.json())
      .then(resJSON => {
        const occupiedAppointments = resJSON.items.map(item => ({
          start: moment(item.start.dateTime),
          end: moment(item.end.dateTime),
        }))

        const hasOccupiedIntersection = sT => {
          return occupiedAppointments.find(oT =>
            moment
              .range(oT.start, oT.end)
              .overlaps(moment.range(sT.start, sT.end))
          )
        }

        while (it.isBefore(endOfPlanning)) {
          weekday = it.weekday()
          if (FREE_TIMESLOTS[weekday]) {
            Array.prototype.push.apply(
              termine,
              FREE_TIMESLOTS[weekday].reduce((acc, time) => {
                const [h, m] = time.split(':')

                const start = it
                  .clone()
                  .hour(parseInt(h, 10))
                  .minute(parseInt(m, 10))
                  .startOf('minute')

                const end = start.clone().add(TERMIN_DURATION)

                const tt = hasOccupiedIntersection({ start, end })

                if (!tt) {
                  acc.push({
                    title: `ab ${time}`,
                    start: start.toDate(),
                    end: end.toDate(),
                  })
                }

                return acc
              }, [])
            )
          }

          it.add(1, 'days')
        }

        this.setState({
          termine,
        })
      })

      .catch(console.error)
  }

  componentDidMount() {
    this.setFreeSlots()
  }

  render() {
    const { selectedTermin, formSuccessfullySubmitted, termine } = this.state

    const terminFormatted = selectedTermin
      ? moment(selectedTermin.start).format('llll')
      : ''

    let currentDay = moment().startOf('day')

    const termineGroupped = termine.reduce((acc, t) => {
      const tDay = moment(t.start).startOf('day')
      const ret = [...acc]

      if (!tDay.isSame(currentDay)) {
        currentDay = tDay
        ret.push({
          day: currentDay.format('dd, D. MMM'),
          termine: [t],
        })
      } else {
        ret[ret.length - 1].termine.push(t)
      }

      return ret
    }, [])


    return (
      <div {...classes()}>
        <div {...classes('Container', null, 'is-hidden-mobile card')}>
          <BigCalendar
            onSelectEvent={this.onSelectEvent}
            localizer={localizer}
            culture="de"
            events={termine}
            startAccessor="start"
            endAccessor="end"
            views={['month']}
            defaultView={'month'}
          />
        </div>
        <div {...classes('Container', 'mobile', 'is-hidden-tablet')}>
          <div className="columns is-mobile is-multiline">
            {termineGroupped.map(tG => (
              <div className="column">
                <div className="is-6 has-text-weight-semibold m-t-sm">{tG.day}</div>
                {tG.termine.map(t => <AgendaTermin event={t}
                                                   key={t.start}
                                                   onClick={this.onSelectEvent}/>)}
              </div>
            ))}
          </div>
        </div>
        <Modal
          modifier="full"
          onClose={this.closeModal}
          modalActive={!!selectedTermin}
        >
          <div className="box p-lg">
            <h4 className="title is-4">
              Ich freue mich, dass Sie sich einen Termin ausgesucht haben.
            </h4>
            <p className="m-b-md">
              Sie können hier eine unverbindliche Terminanfrage
              <br />
              für{' '}
              <em>
                <strong>{terminFormatted}</strong>
              </em>{' '}
              senden.
            </p>
            {!formSuccessfullySubmitted && (
              <SendAnfrageForm
                onSuccess={() => {
                  this.setState({
                    formSuccessfullySubmitted: true,
                  })
                }}
                termin={terminFormatted}
              />
            )}
            {formSuccessfullySubmitted && (
              <>
                <p className="is-success">
                  Ich setze mich gerne bald mit Ihnen in Verbindung.
                </p>
                <div className="has-text-right m-t-md">
                  <button
                    onClick={this.closeModal}
                    className="button is-success"
                  >
                    Schließen
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal>
      </div>
    )
  }
}

export default Calendar
