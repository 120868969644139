import React, { Component } from 'react'
import Calendar from '../components/Calendar/Calendar'
import Layout from '../components/Layout/Layout'

class Termine extends Component {
  render() {
    return (
      <Layout cols={true}
              title="Termine">
        <p className="subtitle">Klicken Sie bitte auf einem freien Termin um die Terminanfrage zu senden.</p>
        <Calendar />
      </Layout>
    )
  }
}

export default Termine
