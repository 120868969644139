import React from 'react'
import { Form } from 'react-form'

class FormComponent extends React.Component {
  getAction() {
    return `/submit-form`
  }

  submit = values => {
    const {onSuccess = () => {}} = this.props
    const url = this.getAction()

    const vals = {
      ...values,
      ...this.props.hidden,
      'form-name': this.props.name,
    }

    const data = new URLSearchParams()

    Object.keys(vals).forEach(key => {
      data.append(key, vals[key])
    })

    fetch(url, {
      method: 'POST',
      body: data,
    })
      .then(() => {
        onSuccess()
      })
      .catch(console.error)
  }

  render() {
    const { children, hidden: hiddenProp, name, onSuccess, ...rest } = this.props

    const hidden = hiddenProp || {}
    hidden['form-name'] = name

    return (
      <Form onSubmit={this.submit}>
        {formApi => (
          <form
            method="POST"
            name={name}
            data-netlify="true"
            onSubmit={formApi.submitForm}
            action={this.getAction()}
            {...rest}
          >
            {Object.keys(hidden).map(hiddenFieldname => (
              <input key={hiddenFieldname} type="hidden" name={hiddenFieldname} value={hidden[hiddenFieldname]} />
            ))}
            {children}
          </form>
        )}
      </Form>
    )
  }
}

export default FormComponent
